<template>
  <div class="server-layout">
    <h2>共享平台注册经营者合作协议书</h2>
    <div class="server-header"><span class="bold">甲 方：</span>{{name}}</div>
    <div class="server-header"><span class="bold">注册地址：</span>{{address}}</div>
    <div class="server-header"><span class="bold">乙 方：</span>身份证号：</div>
    <div class="tips">提示条款</div>
    <p class="intext">继续注册成为平台注册经营者前，请先阅读本协议提示条款：</p>
    <p class="intext">欢迎乙方与甲方【{{name}}】共同签署本《合作协议书》（下称“本协议”）成为本平台的注册经营者，以本平台为业务匹配渠道，个人从事生产经营活动，并取得相应的收益。
    </p>
    <p class="intext">【审慎阅读】为维护乙方的自身权益，在乙方点击同意本协议之前，请认真阅读本协议，务必审慎阅读、充分理解各条款内容。
    </p>
    <p class="intext">【签约】当乙方按照提示填写乙方的信息且完成全部申请后，即表示乙方已充分阅读、理解并接受本协议的全部内容，并与甲方达成一致意见，成为甲方的合作人员，此后乙方不得以未阅读/不理解本协议内容或类似言辞做任何形式的抗辩。阅读本协议的过程中，如果乙方不理解相关内容应及时联系我公司，如乙方不同意本协议或其中任何条款约定，请乙方立即停止任何的申请/注册程序。
    </p>
    <p class="intext">【合作关系】乙方与甲方通过本协议建立平等的业务承揽合作关系，适用《合同法》、《民法总则》和其他民事法律，不适用《劳动法》、《劳动合同法》。
    </p>
    <p class="intext">【保证】乙方向甲方承诺仅通过此平台以个体经营形式与甲方进行合作，与发包方不存在劳动关系或劳务关系。
    </p>

    <div class="tips">协议条款</div>
    <p class="intext">根据国家法律、法规和有关规定，双方按照平等自愿、诚实信用的原则，就乙方通过甲方运营平台承揽发包商。</p>
    <p class="intext">提供的业务，从事生产经营活动等事宜订立本服务合作协议。</p>
    <p class="intext bold">第一条 合作内容</p>
    <p class="intext">1.1 因甲方所运营平台上的发包商（即在甲方所运营的平台上注册的单位客户，后文简称为“发包商”）有寻求个人经营者承担一定工作任务的需求，乙方自愿以平台为业务渠道从事生产经营活动，为更好地规范和履行各方的权利、义务，甲方特此与乙方签订本协议，以供双方严格遵守。为保证乙方与发包商的权利义务，甲方特此向乙方宣贯平台上的业务规则。关于各发包商对注册经营者的要求，包括应履行的活动条件、活动内容、活动注意事项等均在各发包商的任务发布页面有所体现，乙方应当予以遵守。</p>
    <p class="intext">1.2 乙方应按照甲方及各发包商要求的标准接收任务，履行生产经营活动过程中的相关义务并享有相关权利，并严格遵循各发包商制定的任务政策或规则。如乙方违反前述规定，一经核实本公司将有权随时终止本合同，冻结并扣除乙方尚未结算的税后经营收入作为赔偿。</p>
    <p class="intext bold">第二条 协议效力</p>
    <p class="intext">2.1 本协议自乙方完成申请、注册并最终点击 签约 后立即生效。</p>
    <p class="intext">2.2 本协议有效期限为自生效之日起至 年 月 日止（不低于一年）。期限届满，双方应另行签署新的协议；若双方未签署新的协议，而以实际行动继续合作的，视为双方同意协议期限顺延直至按照本协议约定终止或解除时为止。
    </p>
    <p class="intext bold">第三条 甲方的权利和义务</p>
    <p class="intext">3.1 甲方接受发包商的委托，就其所需发包的任务寻求、匹配注册经营者。</p>
    <p class="intext">（即乙方）为其完成相应任务，并按乙方的工作成果向乙方结算承揽任务经营所得（以下称“经营所得”）。</p>
    <p class="intext">3.2 甲方将按发包商制定的注册经营者经营所得计费标准和支付方式，根据发包商确认的数额向乙方结算经营所得，并承诺按时支付（按月结算）。如乙方在工作成果、标准和费用数额上存在异议的，可以向发包商提出，甲方将根据发包商的最终意见结算和支付费用。
    </p>
    <p class="intext">此处乙方应明确：乙方的经营所得由甲方按月结算并完成支付，乙方不应向发包商主张任何费用。</p>
    <p class="intext">3.3 在向乙方支付经营所得前，甲方将根据税务机关的授权代征乙方在前述生产经营过程中产生的个人所得税等各项税款及行政收费（如适用），在收到发包商的付款指令并缴纳所有税费后，甲方将保证乙方的税后经营所得款项及时到账。</p>
    <p class="intext">3.4 乙方根据本协议为平台上的发包商完成任务的，系前述任务的实际承揽人，该任务并不导致乙方与发包商或与甲方之间构成任何劳动、劳务或雇佣关系，乙方应对其承揽行为负责。
    </p>
    <p class="intext">3.5 在乙方完成任务的过程中，应当自行保障人身和财产安全，并注意防范侵害第三人的人身、财产或造成其他损失，如出现问题由乙方自行承担责任。若因乙方在完成任务过程中给发包商或其他第三方造成损失，使甲方或发包商承担责任的，甲方或发包商有权向乙方追偿，并从乙方应得的税后经营所得中扣除。
    </p>
    <p class="intext">3.6 甲方承诺对乙方所披露的个人隐私信息进行保密，但出于履行本协议的需求或有关部门要求而披露的除外。
    </p>
    <p class="intext">3.7 甲方发现乙方违反本协议第四条第 4.6 款之规定的，甲方有权中止本协议，并将乙方的违法行为自发现之时 24 小时之内向甲方主管税务机关报告。乙方应自行承担由此产生的税务相关的处罚责任，包括但不限于根据税务机关的要求补缴相应的税款、滞纳金等。
    </p>
    <p class="intext bold">第四条 乙方的权利和义务</p>
    <p class="intext">4.1 如发包商要求乙方完成违反法律法规的任务的，乙方有权且应当拒绝。
    </p>
    <p class="intext">4.2 乙方注册和签约时需提供必要的签约信息【包括乙方的姓名（或名 称）、身份证号（或登记注册号）、车牌号码、手机号、账号等信息）】，如各单位需要其他具体信息的，请乙方按照发包商的要求进行提供。乙方承诺并保证乙方所提供的信息真实、准确，如甲方或发包商发现乙方伪造或提供虚假信息的，一经核实，甲方有权单方面立即解除本协议，冻结或扣除乙方尚未结算的税后经营所得作为赔偿。如乙方的信息变更（例如，不再符合注册经营者身份要求时等）应当及时通知甲方，否则与此有关的全部法律责任由乙方负责。
    </p>
    <p class="intext">4.3 乙方同意甲方及其发包商收集乙方的个人信息，包括但不限于乙方的姓名（或名称）、身份证号（或登记注册号）、收款账户信息
      、联系电话、接单数量及费用等。
    </p>
    <p class="intext">4.4 因乙方是平台上任务的实际承揽人，应当独立承担相应的责任，甲方没有为乙方购买任何保险的法律义务，乙方自行购买商业保险的，其投保、出险及理赔事宜均与甲方无关。
    </p>
    <p class="intext">4.5 乙方应自备提供完成任务所需要的设备设施，自行承担必要费用和成本。
    </p>
    <p class="intext">4.6 乙方承诺与发包商不具有下列关系的任何一种：
    </p>
    <p class="intext">（1）与发包商具有劳动、劳务或雇佣关系，或其他类似的劳动人事法律关系；
    </p>
    <p class="intext">（2）与发包商的关联企业具有劳动、劳务或雇佣关系，或其他类似的劳动人事法律关系；
    </p>
    <p class="intext">（3）为发包商及其关联企业的法定代表人、董事、监事、股东。前述发包商的关联企业指，该企业与发包商相互间直接或间接持有其中一方的股权总和达到 25%或以上的；或者与发包商直接或间接同为第三者所拥有或控制股权达到 25%或以上的；或者对发包商生产经营、交易具有实际控制的与其他利益相关联的关系（包括但不限于婚姻、近亲属关系）。
    </p>
    <p class="intext">4.7 乙方应保证完成甲方以及发包商所安排工作任务，并与甲方保持必要的沟通联络。
    </p>
    <p class="intext">4.8 乙方应按时尽责地完成符合甲方以及发包商要求的任务，在此过程中不得有侵犯人身权、肖像权、隐私权、名誉权、财产权、知识产权、商业秘密或其他合法权益以及其他违反国家法律法规、国家政策或有悖于公序良俗的行为。
    </p>
    <p class="intext">4.9 协议期内，乙方应在甲方以及发包商许可范围内使用发包商的商业信息；协议期内以及协议终止后，乙方均有义务对双方合作的有关协议及合作过程中知悉的甲方、发包商及他人的相关信息（包括但不限于乙方与甲方签订的本协议，及服务过程中了解到的发包商产品信息、相关服务政策、价格标准、销售数量、技术秘密及其他情况）予以保密，未经书面许可，乙方不得以任何方式向其它方泄漏、给予或转让前述保密信息。否则，乙方需负责赔偿因此导致甲方及发包商的全部损失。
    </p>
    <p class="intext">4.10 除本协议事项外，乙方不能以甲方或发包商的名义开展任何与完成约定的任务内容无关的业务或活动。
    </p>
    <p class="intext">4.11 乙方为发包商完成约定的任务时，发包商可能将要求乙方签署、点击电子协议《发包商与注册经营者的协议》，乙方应同意点击签署。乙方已经明确知悉，点击确认后该《发包商与注册经营者的协议》将对乙方产生法律效力。
    </p>
    <p class="intext">4.12 甲方及本平台默认，乙方于同期间内，只在本平台注册为注册经营者并接受相应的经营所得收入支付和个人所得税等各项税款及行政收费的代缴服务，若乙方在一个税务年度内的累计经营收入超过相应的个人所得税税率档位，请乙方自行在第二年到税务部门汇算清缴，并按规定补缴税款。
    </p>
    <p class="intext bold">第五条 税后经营所得收入的支付</p>
    <p class="intext">5.1 甲方将根据发包商的运营要求，按月向乙方支付税后经营所得收入，具体由甲方根据乙方在发包商的实际服务情况、用户评价以及发包 商的结算规则确定。由于乙方所完成任务的质量、发包商运营情况 等的不同，该收入金额可能会呈现浮动，乙方清楚并了解该等浮动 为正常情况。甲方将以人民币形式向乙方支付税后经营所得收入，乙方应缴纳的个人所得税及其他税费由甲方负责代征代办。
    </p>
    <p class="intext">5.2 乙方应向甲方提供乙方实名登记的银行账户或支付宝账户，具体账户信息以乙方在发包商提供的信息为准。</p>
    <p class="intext">5.3 税后经营所得收入发放以乙方提供的收款账户为准，因乙方提供的收款账户不实造成的一切损失由乙方自行承担。如乙方账号变更或发生不可用等情况时，应及时通过发包商进行变更操作，否则，由此造成的一切损失由乙方自行负责。
    </p>
    <p class="intext bold">第六条 违约责任</p>
    <p class="intext">6.1 双方应按本协议约定履行，如有违反，守约方有权要求对方及时改正；造成对方损失的，守约方有权要求违约方赔偿。如乙方因违约行为给甲方或发包商造成损失的，甲方或发包商有权直接从乙方的应得税后经营所得收入中扣除相应的款项作为赔偿。
    </p>
    <p class="intext">6.2 在合同因违约而终止情况下，守约方可以要求违约方承担违约责任并赔偿损失，赔偿损失的范围应包括守约方因违约方违约所受到的全部经济损失
      （包括直接损失、间接损失以及因此赔偿所引发的一切合理费用的支出），守约方还可保留进一步追究的权利。</p>
    <p class="intext">6.3 6.3 因不可抗力造成损失的，彼此不负赔偿责任，但发生不可抗力一方应及时将有关情况通知另一方，并尽最大努力进行补救。本协议所称不可抗力是指不能预见、不能克服并不能避免且对一方当事人造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、火灾和风暴等，社会事件如战争、动乱、政府行为、黑客事件、大范围电信事故等，以及政府行 为、政策法律改变等。
    </p>
    <p class="intext bold">第七条 协议的终止</p>
    <p class="intext">7.1 本协议在发生下列情形之一时，本协议自动终止：</p>
    <p class="intext">（1）本协议到期，双方未决定继续合作的；</p>
    <p class="intext">（2）乙方主动提出终止任务，且已经发包商同意的；</p>
    <p class="intext">（3）发包商终止或变更业务模式而不需要乙方继续履行本协议的；</p>
    <p class="intext">（4）发包商认为乙方不适合继续承揽任务的；</p>
    <p class="intext">（5）法律法规或政策变更，不再允许双方继续以此模式合作的。</p>
    <p class="intext">7.2 发生以下情形时之一的，甲方可以终止本协议：</p>
    <p class="intext">（1）乙方被证实符合本协议第 4.6 条的情况而不适宜承揽本平台上的任务的；</p>
    <p class="intext">（2）乙方的能力、态度或完成任务的成果如未能达到甲方或发包商的要求，甲方在自行发现乙方的瑕疵或收到发包商的通知，且经甲方核实确认乙方所完成的任务确有瑕疵时，甲方将对乙方给予警告并责令乙方改正；如乙方限期内未能予以改正或此后如仍发生类似情况的，甲方可单方面解除本协议；
    </p>
    <p class="intext">（3）乙方因违反发包商的规定或相应的操作规范，导致发包商有充分的理由认为乙方不适合继续承揽发包商发包的任务的；
    </p>
    <p class="intext">（4）乙方提供虚假信息，从事违法活动，或侵害甲方、发包商、他人的人身、财产或其他权益，情节恶劣或造成严重损失的。
    </p>
    <p class="intext">7.3 一方违反协议约定，且违约方在非违约方书面通知后 15 日内仍未予改正的，守约方可以单方终止协议。
    </p>
    <p class="intext">7.4 本协议的终止不影响已经产生的权利义务关系，也不解除各方的保密义务。
    </p>
    <p class="intext bold">第八条 不可撤销委托授权</p>
    <p class="intext">8.1 涉税事务授权委托</p>
    <p class="intext">（1）合作期限内，乙方授权委托甲方代理本协议交易项下的涉税事务，包括代开增值税普通发票和代缴个税及其他税费。
    </p>
    <p class="intext">（2）乙方确认甲方根据本协议授权，提交的相关申报资料和信息是真实、完整、准确和符合有关法律法规的。
    </p>
    <p class="intext">8.2 信息查询授权委托
    </p>
    <p class="intext">（1）合作期限内，乙方授权甲方向中国人民银行、依法设立的数据服务机构、信息查询及信息验证服务机构、政府部门及其他依法设立存续的第三方机构等无次数限制地收集、查询、验证，同意递交本人相关信息（包括但不限于银行实名信息、通信实名信息、位置信息、资信状况、各类交易记录，公共费用缴纳、违法违规信息、财产状况、行踪轨迹及网络行为信息），进行验证并获得验证结果。
    </p>
    <p class="intext">（2）乙方同意为避免重复授权之不便，本授权表明该等合法存续的第三方机构在使用或向有关机构提供本人信息时，可以依据本授权径行使用或向源数据机构提供本人相关信息，而无需再逐一向本人另行获取授权。</p>
    <p class="intext">8.3 特别提示：为了维护乙方的合法权益，请乙方在充分理解本授权书的全部内容后签署本授权书。本公司将依法对信息进行保密，履行信息安全保障义务。若乙方不接受本授权书任何条款，请乙方立即停止授权。
    </p>
    <p class="intext">8.4 授权人声明：本授权书经接受后立即生效，且效力具有独立性，不因相关业务合同或条款无效或被撤销而无效或失效，本授权一经做出，便不可撤销。以上授权内容，本人已经充分理解并同意，一经本人点击确认或勾选时即视为签署。本人自愿做出以上授权，并愿意承担由此所产生的一切法律后果。
    </p>
    <p class="intext bold">第九条 其他约定
    </p>
    <p class="intext">9.1 因履行本协议发生的纠纷，双方应友好协商解决，协商不成的，提请北京仲裁委员会仲裁，仲裁裁决为一裁终局，对双方具有约束力。</p>
    <p class="intext">9.2 甲方有权根据业务变化情况修改本协议条款。</p>
    <br>
    <br>
    <br>
    <br>
<!--    <van-button class="agree-btn" type="info" @click="agreeded">-->
<!--      已阅读并同意</van-button>-->
    <br>
    <br>
  </div>
</template>
<script>

export default {
  name: 'server',
  data () {
    return {
      time: 9000,
      name: '',
      address: ''
    }
  },
  mounted () {
    this.name = this.$route.query.name
    this.address = this.$route.query.address
  },
  methods: {
    agreeded () {
      this.$router.push({
        name: 'userInfo',
        params: {
          id: true
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
  .server-layout{
    color: #333;
    padding: 50px 40px;
    text-align: left;
    h2{
      text-align: center;
      font-size: 38px;
    }
    .bold{
      font-weight: bold;
    }
    .tips{
      font-size: 30px;
      font-weight: bold;
      padding: 10px 0;
    }
    .server-header{
      font-size: 30px;
    }
    .intext{
      font-size: 14px;
    }
    .agree-btn{
      display: block;
      margin: 0 auto;
    }
  }

</style>
